import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Join the Conversation",
    paragraph: (
      <>
        <span className="text-color-primary">Speakeasy</span> gives great
        conversations a place to flourish. Open the app, choose a topic that
        interests you, and dive in.
      </>
    ),
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container" id="features">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Topics
                </div>
                <h3 className="mt-0 mb-12">Your Topics, Your Passion</h3>
                <p className="m-0">
                  We keep our list of <b>topics</b> diverse and add new ones
                  frequently based on user suggestions, so there's always a
                  discussion on{" "}
                  <span className="text-color-primary">Speakeasy</span> that you
                  and your communities care about.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <div className="deviceContainer app-screenshot">
                  <div className="iphone white portrait">
                    <div className="caseBorder"></div>
                    <div className="case"></div>
                    <div className="reflection"></div>
                    <div className="screen"></div>
                    <div className="camera"></div>
                    <div className="speaker"></div>
                    <div className="homeButtonBorder"></div>
                    <div className="homeButton"></div>
                    <div className="content">
                      <Image
                        src={require("./../../assets/images/screenshot1.jpg")}
                        alt="Features split 01"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Takes
                </div>
                <h3 className="mt-0 mb-12">BYO Opinions</h3>
                <p className="m-0">
                  Record your <b>take</b> on a topic using one of our
                  Conversation Starters, or respond to someone else's take with
                  one of your own.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <div className="deviceContainer app-screenshot">
                  <div className="iphone white portrait">
                    <div className="caseBorder"></div>
                    <div className="case"></div>
                    <div className="reflection"></div>
                    <div className="screen"></div>
                    <div className="camera"></div>
                    <div className="speaker"></div>
                    <div className="homeButtonBorder"></div>
                    <div className="homeButton"></div>
                    <div className="content">
                      <Image
                        src={require("./../../assets/images/screenshot2.jpg")}
                        alt="Features split 02"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Summaries
                </div>
                <h3 className="mt-0 mb-12">Get the Gist</h3>
                <p className="m-0">
                  Listen to all the <b>takes</b> under a <b>topic</b>, or let
                  our algorithm pick a few that represent the key perspectives
                  and opinions in the conversation.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <div className="deviceContainer app-screenshot">
                  <div className="iphone white portrait">
                    <div className="caseBorder"></div>
                    <div className="case"></div>
                    <div className="reflection"></div>
                    <div className="screen"></div>
                    <div className="camera"></div>
                    <div className="speaker"></div>
                    <div className="homeButtonBorder"></div>
                    <div className="homeButton"></div>
                    <div className="content">
                      <Image
                        src={require("./../../assets/images/screenshot3.jpg")}
                        alt="Features split 03"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
