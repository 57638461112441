import React, { useState } from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Cta from "../components/sections/Cta";
import CurrentTopics from "../components/sections/CurrentTopics";
import TakeExplanation from "../components/sections/TakeExplanation";
import Modal from "../components/elements/Modal";

const Home = () => {
  const [thanksModalIsOpen, setThanksModalIsOpen] = useState(false);

  return (
    <>
      <Modal
        show={thanksModalIsOpen}
        handleClose={() => setThanksModalIsOpen(false)}
      >
        Thanks! Your email has been added to the waitlist.
      </Modal>

      <Hero className="illustration-section-01" />
      <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
      {/* <FeaturesTiles /> */}
      <TakeExplanation />
      <CurrentTopics />
      <Cta split onSubmit={() => setThanksModalIsOpen(true)} />
    </>
  );
};

export default Home;
