import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  onSubmit,
  ...props
}) => {
  const [email, setEmail] = useState("");
  const [isWaitlistJoined, setIsWaitlistJoined] = useState(false);

  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  function submitEmail(e) {
    e.preventDefault();
    if (!email) return;
    fetch("https://speakeasy-platform.herokuapp.com/api/waitlist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    }).then(() => {
      setIsWaitlistJoined(true);
      if (onSubmit) onSubmit();
    });
  }

  return (
    <section {...props} className={outerClasses} id="cta">
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">
              <span className="text-color-dark">Speakeasy</span> is launching
              very soon. Leave us your email to be the first to know!
            </h3>
          </div>
          <div className="cta-action">
            <form onSubmit={submitEmail}>
              <Input
                type="email"
                label="Subscribe"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                labelHidden
                hasIcon="right"
                placeholder="Your email"
                disabled={isWaitlistJoined}
              >
                {isWaitlistJoined ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
                      fill="#376DF9"
                    />
                  </svg>
                ) : (
                  <></>
                )}
              </Input>
              <input
                style={{ width: "100%", marginTop: 12 }}
                className="button"
                type="submit"
                disabled={isWaitlistJoined}
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
