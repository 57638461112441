import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const sectionHeader = {
  title: "Preview our Topics",
  paragraph: (
    <>
      Can't wait to get on <span className="text-color-primary">Speakeasy</span>
      ? Click on a topic below and submit a take through your browser. We'll
      make sure your voice is included on the app at launch!
    </>
  ),
};

const CurrentTopics = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    "topics",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses} id="topics">
      <div className="container reveal-from-bottom">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className="topic-tile-container reveal-from-right">
            <a
              href="https://submitatake.com/topic/mena"
              className="topic-tile"
              style={{
                background: `url(https://ondeck-media.s3.amazonaws.com/topic-images/mena-colorized.jpg) 0% 0% / cover no-repeat`,
              }}
            >
              <i class="fas fa-arrow-right"></i>
              <div>MENA</div>
            </a>
            <a
              href="https://submitatake.com/topic/election%202020"
              className="topic-tile"
              style={{
                background: `url(https://ondeck-media.s3.amazonaws.com/topic-images/elections-colorized.jpg) 0% 0% / cover no-repeat`,
              }}
            >
              <i class="fas fa-arrow-right"></i>
              <div>Election 2020</div>
            </a>
            <a
              href="https://submitatake.com/topic/china"
              className="topic-tile"
              style={{
                background: `url(https://ondeck-media.s3.amazonaws.com/topic-images/china-colorized.jpg) 0% 0% / cover no-repeat`,
              }}
            >
              <i class="fas fa-arrow-right"></i>
              <div>China</div>
            </a>
            <a
              href="https://submitatake.com/topic/ice%20cream"
              className="topic-tile"
              style={{
                background: `url(https://ondeck-media.s3.amazonaws.com/topic-images/ice-cream-colorized.jpg) 0% 0% / cover no-repeat`,
              }}
            >
              <i class="fas fa-arrow-right"></i>
              <div>Ice Cream</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

CurrentTopics.propTypes = propTypes;
CurrentTopics.defaultProps = defaultProps;

export default CurrentTopics;
