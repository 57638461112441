import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import "react-soundplayer/styles/buttons.css";
import "react-soundplayer/styles/cover.css";
import "react-soundplayer/styles/progress.css";
import "react-soundplayer/styles/icons.css";

import { PlayButton, Timer, Progress } from "react-soundplayer/components";
import { withCustomAudio } from "react-soundplayer/addons";

const propTypes = {
  children: PropTypes.node,
  ...SectionProps.types,
};

const defaultProps = {
  children: null,
  ...SectionProps.defaults,
};

const AWSSoundPlayer = withCustomAudio((props) => {
  const {
    trackTitle,
    artistName,
    currentTime,
    duration,
    backgroundUrl,
  } = props;

  return (
    <div
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${backgroundUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        textAlign: "center",
        padding: "0.5rem",
        margin: "1rem 0",
      }}
    >
      <div style={{ fontSize: "0.8rem", color: "white" }}>{trackTitle}</div>
      <div style={{ fontSize: "0.5rem", lineHeight: "normal" }}>
        -{artistName}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PlayButton {...props} />
        <Progress value={(currentTime / duration) * 100 || 0} {...props} />
        <Timer
          style={{ paddingLeft: 10, fontSize: "0.5rem", lineHeight: "1.15" }}
          {...props}
        />
      </div>
    </div>
  );
});

const TakeExplanation = ({
  className,
  children,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={"tiles-wrap testimonial"}>
            <div
              className="tiles-item reveal-from-right"
              style={{ maxWidth: "100%", flex: 1 }}
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <h2 style={{ marginTop: 0 }}>What's a Take?</h2>
                  <p className="text-sm mb-0">
                    On <span className="text-color-primary">Speakeasy</span>, a{" "}
                    <b>take</b> is a short (~5 minute) recording of a user
                    explaining his/her opinion on a topic. You don't need to be
                    an expert to record a take&mdash;we try to keep things
                    casual, and we're all here to learn. Just be kind and
                    respectful, and try to take a definite stance.
                  </p>
                  <br />
                  <p className="text-sm mb-0">
                    Still not sure what makes a good take? Listen to one here:{" "}
                  </p>
                  <AWSSoundPlayer
                    streamUrl={
                      "https://ondeck-media.s3.amazonaws.com/Immigration-2-fcc110ad-bc17-416f-901d-7d6e6f9f564c.mp3"
                    }
                    trackTitle={"We Need More Immigrants"}
                    artistName={
                      <span>Doug Rand (Co-Founder, Boundless Immigration)</span>
                    }
                    backgroundUrl={
                      "https://ondeck-media.s3.amazonaws.com/topic-images/immigration-colorized.jpg"
                    }
                    preloadType="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TakeExplanation.propTypes = propTypes;
TakeExplanation.defaultProps = defaultProps;

export default TakeExplanation;
